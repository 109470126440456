import BaseSelect from '@/components/common/base-select/base-select.vue'
import supplierSelect from '@/components/packages/supplier-select/supplier-select'
import { formatDate } from '@/utils/auth/common'
const applyStateOptions = [
  { name: '拒绝', id: '0' },
  { name: '通过', id: '1' }
  // { name: '申请中', id: '2' },
]
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '授信银行',
      prop: 'capitalSideName',
      attrs: {
        placeholder: '请选择',
        selectedField: ['capitalSideName', 'capitalSideName'],
        options: content.fundsproviderData,
        clearable: true
      },
      tag: BaseSelect
    },
    {
      label: '银行融资申请编号',
      prop: 'postalFinancingCode',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        filterable: true,
        selectedField: ['keyId', 'enterpriseName'],
        maxlength: 50,
        ref: 'searchSelect'
      },
      tag: supplierSelect
    },
    {
      label: '审批结果',
      prop: 'approvalResults',
      attrs: {
        placeholder: '请选择',
        options: applyStateOptions,
        selectedField: ['id', 'name'],
        clearable: true
      },
      tag: BaseSelect,
      isHidden: content.fold
    }
  ]
}

// 表格table
export const dataTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '60px'
    },
    {
      label: '授信银行',
      prop: 'capitalSideName',
      HiddenOverflow: true,
      minWidth: '150px'
    },
    {
      label: '银行融资申请编号',
      prop: 'postalFinancingCode',
      minWidth: '150px',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商名称',
      prop: 'supplierName',
      minWidth: '150px',
      align: 'left'
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      minWidth: '150px'
    },
    {
      label: '审批结果',
      prop: 'approvalResults',
      minWidth: '150px',
      formatter: row => {
        const [obj] = applyStateOptions.filter(item => item.id === row.approvalResults)
        return obj ? obj.name : ''
      }
    },
    {
      label: '授信编号',
      prop: 'creditNo',
      minWidth: '150px'
    },
    {
      label: '授信额度',
      prop: 'creditAmt',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.creditAmt ? row.creditAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '授信起期',
      prop: 'creditStartTime',
      minWidth: '150px',
      formatter: row => {
        return formatDate(row.creditStartTime, 'YY-MM-DD')
      }
    },
    {
      label: '授信止期',
      prop: 'creditEndTime',
      minWidth: '150px',
      formatter: row => {
        return formatDate(row.creditEndTime, 'YY-MM-DD')
      }
    },
    {
      label: '拒绝原因',
      prop: 'refusalReason',
      minWidth: '150px',
      align: 'left',
      formatter: row => {
        if (row.approvalResults === '1') {
          return '--'
        } else {
          return row.refusalReason ? row.refusalReason : '--'
        }
      }
    },
    {
      label: '接收数据时间',
      prop: 'addTime',
      minWidth: '150px',
      formatter: row => {
        return formatDate(row.addTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '操作',
      prop: 'action',
      minWidth: '120px',
      fixed: 'right'
    }
  ]
}

// 查询form
export const productionConfig = (content) => {
  return [
    {
      label: '付款单编码',
      prop: 'easNo',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '稳诚业务编号',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '申请日期',
      prop: 'applyDate',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期',
        'value-format': 'yyyy-MM-dd'
      },
      tag: 'el-date-picker'
    }
  ]
}
// 下级表格table
export const expandTable = (content) => {
  return [
    // {
    //   type: 'selection',
    //   width: '40px'
    // },
    // {
    //   label: '序号',
    //   prop: 'index',
    //   width: '80px'
    // },
    {
      label: '付款单编号',
      prop: 'easNo',
      minWidth: '150px'
    },
    {
      label: '稳诚业务编号',
      prop: 'businessNo',
      minWidth: '150px'
    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      minWidth: '150px'
    },
    {
      label: '融资金额',
      prop: 'financingAmt',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.financingAmt ? row.financingAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '融资期限',
      prop: 'financingTerm',
      minWidth: '150px'
    },
    {
      label: '合同编码',
      prop: 'contractCode',
      minWidth: '150px'
    },
    {
      label: '付款单申报金额',
      prop: 'paymentApplyAmt',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.paymentApplyAmt ? row.paymentApplyAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '稳诚一方建议融资金额',
      prop: 'proposedFinancingAmt',
      minWidth: '170px',
      align: 'right',
      formatter: row => {
        return (row.proposedFinancingAmt ? row.proposedFinancingAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '申请日期',
      prop: 'applyDate',
      minWidth: '150px',
      formatter: row => {
        return formatDate(row.applyDate, 'YY-MM-DD')
      }
    },
    {
      label: '业务状态',
      prop: 'businessStateText',
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      minWidth: '150px',
      fixed: 'right'
    }
  ]
}
