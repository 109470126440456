<template>
  <!-- 查看详情信息 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1200px"
    :showFooter="false"
    title="查看"
    class="dialog"
  >
    <div class="addProcess">
      <el-steps :active="stepActive" align-center class="handlerSteps">
        <!-- 待推送/推送失败 -->
        <el-step :title="progressData.length > 0 ?progressData[0].statusName:'推送信息'" :description="showTime(0)">
          <i :class="[ showClass(0,'push') ,'stepIcon']" slot="icon"></i>
        </el-step>
        <!-- 放款信息-->
        <el-step :title="progressData.length > 1 ? progressData[1].statusName:'放款信息'"  :description="showTime(1)">
          <i :class="[ showClass(1,'loan') ,'stepIcon']" slot="icon"></i>
        </el-step>
        <!-- 清分信息 -->
        <el-step :title="progressData.length > 2 ? progressData[2].statusName:'清分信息'"  :description="showTime(2)">
          <i :class="[showClass(2,'clearing') ,'stepIcon']" slot="icon"></i>
        </el-step>
        <!-- 还款信息 -->
        <el-step :title="progressData.length > 3 ? progressData[3].statusName:'还款信息'"  :description="showTime(3)">
          <i :class="[showClass(3,'refund') ,'stepIcon']" slot="icon"></i>
        </el-step>
      </el-steps>
      <!-- 放款信息 -->
      <p>放款信息</p>
      <base-table
        :columns="loanInfoColumns"
        :showPage="false"
        :tableAttrs="{
          data: loanResultsData,
          stripe: true,
        }"
        :webPage="true"
        :dataSource.sync="loanResultsData"
        ref="tableData"
        :selfChangeHeight="500"
        :isCaculateHeight="true"
      >
        <template slot="index" slot-scope="scope">
          {{scope.$index + 1}}
        </template>
      </base-table>
      <!-- 清分信息 -->
      <p>清分信息</p>
      <base-table
        :columns="clearingInfoColumns"
        :showPage="false"
        :tableAttrs="{
          data: repaymentClearingData,
          stripe: true,
        }"
        :webPage="true"
        :dataSource.sync="repaymentClearingData"
        ref="tableData"
        :selfChangeHeight="400"
        :isCaculateHeight="true"
      >
        <template slot="index" slot-scope="scope">
          {{scope.$index + 1}}
        </template>
      </base-table>
      <!-- 还款信息 -->
      <p>还款信息</p>
      <base-table
        :columns="repaymentInfoColumns"
        :showPage="false"
        :tableAttrs="{
          data: repaymentResultData,
          stripe: true,
        }"
        :webPage="true"
        :dataSource.sync="repaymentResultData"
        ref="tableData"
        :selfChangeHeight="400"
        :isCaculateHeight="true"
      >
        <template slot="index" slot-scope="scope">
          {{scope.$index + 1}}
        </template>
      </base-table>
    </div>
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>

import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
// import FormInfo from '@/components/packages/form-info/form-info.vue'
import { productioValueColumns, loanInfoColumns, clearingInfoColumns, repaymentInfoColumns } from '../utils/look-details-config'
import { psbcApi } from '@/api/psbcApi'
import { formatDate } from '@/utils/auth/common'
export default {
  components: { BaseDialog, BaseButton, BaseTable },
  props: {
    visible: Boolean,
    clearingData: Object
  },
  data () {
    return {
      formatDate,
      stepActive: 0,
      queryParas: {},
      loadCount: 0,
      loanResultsData: [],
      repaymentClearingData: [],
      repaymentResultData: [],
      visibleDetail: false,
      name: '',
      contractId: '',
      keyId: '',
      visibleOrderDetails: false,
      progressData: [],
      stepData: {
        20: 'push', // { name: '待推送', state: 'finish' },
        21: 'pushS',
        22: 'pushE',
        39: 'loanS', // 部分放款
        40: 'loanS',
        41: 'loanE',
        42: 'loanE',
        43: 'clearingS',
        50: 'refundS',
        51: 'refundS'
      }
    }
  },
  computed: {
    // 产值信息
    productioValueColumns () {
      return productioValueColumns(this)
    },
    // 放款信息
    loanInfoColumns () {
      return loanInfoColumns(this)
    },
    // 清分信息
    clearingInfoColumns () {
      return clearingInfoColumns(this)
    },
    // 还款信息
    repaymentInfoColumns () {
      return repaymentInfoColumns(this)
    },
    api () {
      return psbcApi
    },
    // 判断机构类型是不是稳城一方人员显示列
    isHidden () {
      return this.$store.state.user.userInfo.userType !== '20'
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    currentVisible (val) {
      if (val) {
        this.getPaymentProgress()
        this.getCreditFinDetail()
      }
    }
  },
  methods: {
    // 显示图标
    showClass (index, defaults) {
      if (this.progressData.length > index) {
        return this.stepData[this.progressData[index].statusCode]
      } else {
        return defaults
      }
    },
    // 显示时间
    showTime (index) {
      if (this.progressData.length > index) {
        return formatDate(this.progressData[index].businessTime, 'YY-MM-DD hh:mm:ss')
      } else {
        return ''
      }
    },
    getPaymentProgress () {
      const params = {
        easNo: this.clearingData.easNo
      }
      this.progressData = []
      this.api.getPaymentProgress(params).then(res => {
        if (res.data) {
          res.data.forEach(ele => {
            if (ele && ele.length > 0) {
              this.progressData.push(ele[0])
            }
          })
          const length = this.progressData.length
          if (length === 1) {
            this.stepActive = this.progressData[0].statusCode === '21' ? 1 : 0
          } else if (length === 2) {
            this.stepActive = (this.progressData[1].statusCode === '39' || this.progressData[1].statusCode === '40') ? 2 : 1
          } else if (length === 3) {
            this.stepActive = 3
          } else if (length === 4) {
            this.stepActive = 4
          }
        } else {
          this.stepActive = 0
        }
      })
    },
    // 授信融资信息详情
    getCreditFinDetail () {
      const params = {
        creditFinancingId: this.clearingData.keyId
      }
      this.api.getCreditFinDetail(params).then(res => {
        if (res.data && JSON.stringify(res.data) !== '{}') {
          // 放款信息
          this.loanResultsData = res.data.psbcLoanResultsVOList
          // 清分信息
          this.repaymentClearingData = res.data.psbcRepaymentClearingVOList
          // 还款信息
          this.repaymentResultData = res.data.psbcRepaymentResultVOList
        }
      })
    },
    // 查看项目合同信息详情
    contractDetail (row) {
      this.name = '合同名称：' + row.contractName
      this.keyId = row.keyId
      this.contractId = row.contractId
      this.visibleDetail = true
    },
    // 查看付款单信息
    paymentOrderDetail (row) {
      this.keyId = row.keyId
      this.visibleOrderDetails = true
    }
  }
}
</script>
<style lang="scss">
.handlerSteps {
  margin: 0px 100px;
  .el-step__line {
    background: #eae8e4;
  }
  .el-step__icon {
    width: 56px;
    height: 56px;
  }
  .el-step__icon.is-text {
    border: none;
  }
  .el-step__head.is-finish {
    color: #eae8e4;
    border-color: #eae8e4;
  }
  .el-step__title.is-finish,.el-step__description.is-finish {
    color: #666;
  }
  .el-step__title.is-process {
    color: #e08c16;
  }
  .el-step.is-horizontal .el-step__line {
    height: 4px;
    top: 25px;
  }
  .stepIcon {
    width: 56px;
    height: 56px;
    background-size: 100% 100%;
  }
  // 推送
  .push {
    background-image: url('~@/assets/flow/push.png');
  }
  .pushE {
    background-image: url('~@/assets/flow/pushE.png');
  }
  .pushS {
    background-image: url('~@/assets/flow/pushS.png');
  }
  // 放款
  .loan {
    background-image: url('~@/assets/flow/loan.png');
  }
  .loanS {
    background-image: url('~@/assets/flow/loanS.png');
  }
  .loanE {
    background-image: url('~@/assets/flow/loanE.png');
  }
  // 清分
  .clearing {
    background-image: url('~@/assets/flow/clearing.png');
  }
  .clearingS {
    background-image: url('~@/assets/flow/clearingS.png');
  }
  // 还款
  .refund {
    background-image: url('~@/assets/flow/refund.png');
  }
  .refundS {
    background-image: url('~@/assets/flow/refundS.png');
  }
}
</style>
