<!-- 正式授信管理列表 -->
<template>
  <div class="financingDecisionManageList">
    <backtitle></backtitle>
    <!-- 条件查询 -->
    <base-form
      :componentList="productionConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <!-- 列表数据 -->
    <base-table
      class="main-page-table"
      :columns="expandColumns"
      :showPage="false"
      :tableAttrs="{
        stripe: true
      }"
      :dataSource.sync="tableData"
      :queryParas='queryParas'
      ref="tableData"
      :api="api"
      :getApi="'getCreditFinancingPage'"
      :loadCount="loadCount"
      :webPage="true">
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <template slot="easNo" slot-scope="scope">
        <span class="contractName" @click="paymentOrderDetail(scope.row)">{{scope.row.easNo}}</span>
      </template>
      <template slot="businessNo" slot-scope="scope">
        <span class="contractName" @click="businessDetail(scope.row)">{{scope.row.businessNo}}</span>
      </template>
      <template slot="contractCode" slot-scope="scope">
        <span class="contractName" @click="contractDetail(scope.row)">{{scope.row.contractCode}}</span>
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 待推送、推送失败、放款失败、拒绝 -->
        <IconButton v-if="isShowBtn && (scope.row.businessState =='20' || scope.row.businessState =='22' || scope.row.businessState =='41' || scope.row.businessState =='42')" @click="pushProduction(scope.row)" content="推送产值" icon="iconfont icontuisong"></IconButton>
        <!-- 部分放款、已放款、部分还款 -->
        <IconButton v-if="isShowBtn && (scope.row.businessState ==='39' || scope.row.businessState ==='40' || scope.row.businessState ==='50')" @click="applyFor(scope.row, 'E')" content="清分" icon="iconfont iconqingfen"></IconButton>
        <IconButton v-if="scope.row.businessState != '20'" @click="lookDetails(scope.row, 'V')" content="查看" icon="iconfont iconchakan"></IconButton>
      </template>
    </base-table>
    <!-- 清分弹窗 -->
    <clearing-dialog :visible.sync="visible" v-if="visible" @clearance="handleFilter" :clearingData="clearingData" :gysId="creditData.gysId" :keyId="keyId"></clearing-dialog>
    <!-- 查看详情 -->
    <look-details :visible.sync="lookVisible" :clearingData="clearingData"></look-details>
    <!-- 合同弹出框  -->
    <contract-details
      :visible.sync="visibleDetail"
      :name="name"
      :contractId="contractId"
      :keyId="keyId"
    />
    <!-- 付款单详情信息 -->
    <payment-order-details-dialog :visible.sync="visibleOrderDetails" v-if="visibleOrderDetails" :keyId="keyId"></payment-order-details-dialog>
    <!-- 融资申请详情 -->
    <access-documents-make-dialog :visible.sync="visblebusinesDetails" v-if="visblebusinesDetails" :businessId="businessId"></access-documents-make-dialog>
  </div>
</template>
<script>
import Backtitle from '../components/backtitle.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form//base-form.vue'
import { productionConfig, expandTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import ClearingDialog from './components/clearing-dialog.vue'
import LookDetails from './components/look-details.vue'
import ContractDetails from '@/pages/assets-manage/project-information/components/contract-details.vue'
import PaymentOrderDetailsDialog from '@/pages/assets-manage/payment-order-details/components/payment-order-details-dialog.vue'
import AccessDocumentsMakeDialog from '@/pages/business/financing-application-management/components/access-documents-make-dialog.vue'
import { psbcApi } from '@/api/psbcApi'
import { getDictLists } from '@/filters/fromDict'
import Storage from '@/utils/storage'
export default {
  name: 'financingDecisionManageList',
  components: { Backtitle, baseForm, BaseTable, IconButton, ClearingDialog, LookDetails, ContractDetails, PaymentOrderDetailsDialog, AccessDocumentsMakeDialog },
  data () {
    return {
      visible: false,
      count: 0,
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        applyDate: []
      },
      loadCount: 0,
      tableData: [],
      lookVisible: false,
      visibleDetail: false,
      name: '',
      contractId: '',
      keyId: '',
      visibleOrderDetails: false,
      visblebusinesDetails: false,
      businessId: '',
      creditData: {},
      clearingData: {}
    }
  },
  computed: {
    productionConfig () {
      return productionConfig(this)
    },
    expandColumns () {
      return expandTable(this)
    },
    api () {
      return psbcApi
    },
    fileMakeData () {
      return getDictLists('FILE_MAKE_STATUS')
    },
    // 判断机构类型是不是稳城一方人员显示按钮
    isShowBtn () {
      return this.$store.state.user.userInfo.userType === '20'
    }
  },
  watch: {
    'queryParas.applyDate': {
      handler (val) {
        if (val) {
          this.$set(this.queryParas, 'startApplyDate', val[0])
          this.$set(this.queryParas, 'endApplyDate', val[1])
        } else {
          this.$set(this.queryParas, 'startApplyDate', '')
          this.$set(this.queryParas, 'endApplyDate', '')
        }
      }
    }
  },
  mounted () {
    this.creditData = Storage.getSession('creditData')
    this.queryParas = {
      ...this.queryParas,
      keyId: this.creditData.keyId,
      creditCode: this.creditData.creditCode,
      supplierName: this.creditData.supplierName,
      gysId: this.creditData.gysId,
      isNewest: this.creditData.isNewest
    }
    this.handleFilter()
  },
  // activated () {
  //   this.handleFilter()
  // },
  methods: {
    handleFilter () {
      // this.loadCount++
      this.api.getCreditFinancingPage(this.queryParas).then(res => {
        this.tableData = res.data
      })
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        applyDate: [],
        keyId: this.creditData.keyId,
        creditCode: this.creditData.creditCode,
        supplierName: this.creditData.supplierName,
        gysId: this.creditData.gysId,
        isNewest: this.creditData.isNewest
      }
    },
    // 查看项目合同信息详情
    contractDetail (row) {
      this.name = '合同名称：' + row.contractName
      this.keyId = row.contractKeyId
      this.contractId = row.contractId
      this.visibleDetail = true
    },
    // 查看付款单信息
    paymentOrderDetail (row) {
      this.keyId = row.easNoKeyId
      this.visibleOrderDetails = true
    },
    // 融资详情
    businessDetail (row) {
      this.businessId = row.businessKeyId
      Storage.setLocal('admittanceInfo', row)
      this.visblebusinesDetails = true
    },
    // 推送产值
    pushProduction (data) {
      this.$stateConfirm({
        title: '提示',
        message: '是否确认推送产值？',
        show: true,
        type: 'warning'
      }).then(() => {
        // const params = {
        //   easNo: data.easNo,
        //   formalCreditId: data.formalCreditId,
        //   keyId: data.keyId
        // }
        this.api.outputValuePush(data).then(res => {
          this.success('推送成功')
          this.handleFilter()
        }).catch(() => {
          this.handleFilter()
        })
      })
    },
    // 清分
    applyFor (data) {
      this.clearingData = JSON.parse(JSON.stringify(data))
      this.api.getClearingEchoData({ easNo: data.easNo }).then(res => {
        this.clearingData = { ...this.clearingData, ...res.data }
      })
      this.keyId = data.keyId
      this.visible = true
    },
    // 查看授信详情
    lookDetails (data) {
      this.clearingData = JSON.parse(JSON.stringify(data))
      this.lookVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>

.contractName {
  color: #2862e7;
  cursor: pointer;
}
</style>
