// import { getDictLists } from '@/filters/fromDict'
import JumpText from '../components/jump-text.vue'
import { formatDate } from '@/utils/auth/common'
export const basicInfo = (content) => {
  const span = 8
  return [
    {
      label: '付款单编号',
      prop: 'businessNo',
      span,
      attrs: {
        type: '1',
        data: content.backData
      },
      tag: JumpText
    },
    {
      label: '稳诚业务编号',
      prop: 'businessNo',
      span,
      attrs: {
        type: '2',
        data: content.backData
      },
      tag: JumpText
    },
    {
      label: '融资产品',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '融资金额(元)',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '融资期限',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '合同编码',
      prop: 'businessNo',
      span,
      attrs: {
        type: '3',
        data: content.backData
      },
      tag: JumpText
    },
    {
      label: '付款单申报金额(元)',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '稳诚一方建议融资金额(元)',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '申请日期',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '业务状态',
      prop: 'gysCompanyName',
      span
    }
  ]
}
// 产值表格
export const productioValueColumns = (content) => {
  return [
    {
      label: '合同名称',
      prop: 'contractName'
    },
    {
      label: '合同编号',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '合同金额',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '实际合同名称',
      prop: 'bankName',
      align: 'left',
      minWidth: '100px'
    },
    {
      label: '项目公司名称',
      prop: 'bankName',
      align: 'left',
      minWidth: '100px'
    },
    {
      label: '合同对价',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '转让价值',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '对应融资额',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '质押率(%)',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '付款申请单编号',
      prop: 'paymentOrder',
      align: 'left',
      minWidth: '100px'
    },
    {
      label: '合同融资金额合计',
      prop: 'bankName',
      align: 'left',
      minWidth: '110px'
    }
  ]
}
// 放款信息
export const loanInfoColumns = (content) => {
  return [
    {
      label: '借据编号',
      prop: 'evidenceCode'
    },
    {
      label: '借据金额',
      prop: 'evidenceSum',
      align: 'right',
      formatter: row => {
        return (row.evidenceSum ? row.evidenceSum.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '借据起期',
      prop: 'evidenceStartTime',
      formatter: row => {
        return formatDate(row.evidenceStartTime, 'YY/MM/DD')
      }
    },
    {
      label: '借据止期',
      prop: 'evidenceEndTime',
      formatter: row => {
        return formatDate(row.evidenceEndTime, 'YY/MM/DD')
      }
    },
    {
      label: '融资合同币种',
      prop: 'financingCurrency'
    },
    {
      label: '正常利率(%)',
      prop: 'normalRate',
      isHidden: content.isHidden
    },
    {
      label: '放款结果状态',
      prop: 'loanState'
    },
    {
      label: '放款结果信息',
      prop: 'loanResult',
      align: 'left'
    },
    {
      label: '付款申请单编码',
      prop: 'easNo',
      align: 'left',
      minWidth: '100px'
    },
    {
      label: '申报金额(元)',
      prop: 'accountNo',
      align: 'right',
      formatter: row => {
        return (row.accountNo ? row.accountNo.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '放款时间',
      prop: 'evidenceStartTime',
      formatter: row => {
        return formatDate(row.evidenceStartTime, 'YY/MM/DD')
      }
    }
  ]
}
//  清分信息
export const clearingInfoColumns = (content) => {
  return [
    {
      label: '借据编号',
      prop: 'evidenceCode'
    },
    {
      label: '清分总金额',
      prop: 'clearingAmt',
      align: 'right',
      formatter: row => {
        return (row.clearingAmt ? row.clearingAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '供应商二级清分账户户名',
      prop: 'clearingAccountName',
      minWidth: '130px'
    },
    {
      label: '供应商二级清分账户账号',
      prop: 'clearingAccount',
      minWidth: '130px'
    },
    {
      label: '付款申请单编码',
      prop: 'easNo'
    },
    {
      label: '申报金额(元)',
      prop: 'bankName',
      align: 'right',
      formatter: row => {
        return (row.overduePunishAmt ? row.overduePunishAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '审定金额(元)',
      prop: 'examineAmt',
      align: 'right',
      formatter: row => {
        return (row.examineAmt ? row.examineAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '清分时间',
      prop: 'addTime',
      formatter: row => {
        return formatDate(row.addTime, 'YY/MM/DD hh:mm:ss')
      }
    }
  ]
}
//  还款信息
export const repaymentInfoColumns = (content) => {
  return [
    {
      label: '借据编号',
      prop: 'evidenceCode'
    },
    {
      label: '贷款起期',
      prop: 'loanStartTime',
      formatter: row => {
        return formatDate(row.loanStartTime, 'YY/MM/DD')
      }
    },
    {
      label: '贷款止期',
      prop: 'loansEndTime',
      formatter: row => {
        return formatDate(row.loansEndTime, 'YY/MM/DD')
      }
    },
    {
      label: '贷款利率',
      prop: 'loansRate',
      isHidden: content.isHidden
    },
    {
      label: '还款方式',
      prop: 'paymentType',
      formatter: row => {
        return row.paymentType !== '0' ? row.paymentType : ''
      }
    },
    {
      label: '贷款状态',
      prop: 'loansState'
    },
    {
      label: '逾期天数',
      prop: 'overdueDays'
    },
    {
      label: '逾期罚息金额',
      prop: 'overduePunishAmt',
      align: 'right',
      formatter: row => {
        return (row.overduePunishAmt ? row.overduePunishAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '提前还款类型',
      prop: 'prePaymentType',
      formatter: row => {
        return row.prePaymentType ? row.prePaymentType : '--'
      }
    },
    {
      label: '提前还款金额',
      prop: 'prePaymentSum',
      align: 'right',
      formatter: row => {
        return (row.prePaymentSum ? row.prePaymentSum.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '贷款金额',
      prop: 'loansBalance',
      align: 'right',
      formatter: row => {
        return (row.loansBalance ? row.loansBalance.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    }
  ]
}
