<template>
  <!-- 维护清分弹窗 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    title="维护清分"
    class="dialog"
  >
    <div class="addProcess">
      <base-form
        :componentList="addForm"
        :formAttrs="{
          labelWidth: '140px',
          model: formData
        }"
        class="formStyle"
        ref="addFormData"
        :showBtns="false"
      >
      </base-form>
    </div>
    <template slot="footer">
      <base-button label="提交清分" :loading="loading" @click="submit"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false;"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>

import baseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { accountForm } from '../utils/clearing-config'
import { psbcApi } from '@/api/psbcApi'
import Storage from '@/utils/storage'
export default {
  components: { baseForm, BaseDialog, BaseButton },
  props: {
    visible: Boolean,
    clearingData: Object,
    gysId: String,
    keyId: String
  },
  data () {
    return {
      // formData: {},
      accountData: { account: '' },
      queryParas: {},
      loadCount: 0,
      tableData: [],
      changeData: [],
      creditData: {},
      loading: false
    }
  },
  computed: {
    addForm () {
      return accountForm(this)
    },
    api () {
      return psbcApi
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    formData () {
      return this.clearingData
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
        })
      } else {
        this.$refs.addFormData.clearValidate()
      }
    }
  },
  mounted () {
    this.creditData = Storage.getSession('creditData')
  },
  methods: {
    selectAccount (data) {
      console.log(data)
      this.$set(this.formData, 'accountName', data.account)
      this.$set(this.formData, 'clearingBankName', data.bankName)
      this.$set(this.formData, 'accountNo', data.accountNo)
    },
    // 提交新增修改
    submit () {
      this.$refs.addFormData.validate((valid) => {
        if (valid) {
          const params = {
            ...this.formData,
            companyName: this.creditData.supplierName,
            creditCode: this.creditData.creditCode,
            creditFinancingId: this.keyId
          }
          if (parseFloat(this.formData.sortingAmount) < parseFloat(this.formData.evidenceSum)) {
            this.$confirm('清分总金额小于放款金额，是否提交?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.loading = true
              this.api.repaymentClearancePush(params).then(res => {
                this.success('清分成功')
                this.$emit('clearance', true)
                this.currentVisible = false
                this.loading = false
              }).catch(() => {
                this.loading = false
              })
            })
          } else {
            this.loading = true
            this.api.repaymentClearancePush(params).then(res => {
              this.success('清分成功')
              this.$emit('clearance', true)
              this.currentVisible = false
              this.loading = false
            }).catch(() => {
              this.loading = false
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog{
  height: 500px;
}
</style>
